<template>
  <div class="contanier">
    <b-card>
      <h3>{{ $t("items.edit") }}</h3>
      <hr />
      <div>
        <b-alert v-if="getErrorMsg" show variant="danger">{{
          getErrorMsg
        }}</b-alert>
        <div v-if="isLoading || isUpdating">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <section v-else>
          <b-form @submit="onSubmit">
            <p><strong>Id:</strong> {{ this.getCurrentProject.id }}</p>
            <b-form-group>
              <label>Project No</label>
              <b-form-input
                id="projectNo"
                readonly
                v-model="project.projectNo"
                aria-required=""
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-1"
              label="Item name:"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="project.name"
                type="text"
                required
                placeholder="name"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-3"
              label="description:"
              label-for="input-3"
            >
              <b-form-input
                id="input-3"
                v-model="project.description"
                required
                autocomplete="false"
                placeholder="Description"
              ></b-form-input>
            </b-form-group>

            <b-button type="submit" variant="primary">Save</b-button>
            <b-button type="reset" class="ml-3" variant="danger"
              >Reset</b-button
            >
            <Back-Button class="ml-3"></Back-Button>
          </b-form>
        </section>
        <!-- <b-card class="mt-3" header="Form Data Result">
        <pre class="m-0">{{ getItem }}</pre>
        </b-card> -->
      </div>
    </b-card>
    <!-- <b-card>
        <pre>
{{ this.project}}
        </pre>
    </b-card> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BackButton from "@/components/Buttons/BackButton";

export default {
  name: "projectEdit",
  components: {
    BackButton
  },
  data() {
    return {
      isUpdating: false,
      project: {
        name: "",
        projectNo: 0,
        description: "1",
        startDate: "0001-01-01T00:00:00",
        endDate: "0001-01-01T00:00:00",
        isActive: false,
        createdBy: null
      }
    };
  },
  methods: {
    ...mapActions(["loadProject", "updateProject"]),
    onSubmit(e) {
      this.isUpdating = true;
      e.preventDefault();
      this.updateProject(this.project).then(() => {
        this.isUpdating = false;
      });
    }
  },
  computed: mapGetters([
    "getCurrentProject",
    "getErrorMsg",
    "allProjects",
    "isLoading"
  ]),
  mounted() {
    var self = this;
    this.loadProject(this.$route.params.id).then(() => {
      self.project.name = self.getCurrentProject.name;
      self.project.projectNo = self.getCurrentProject.projectNo;
      self.project.description = self.getCurrentProject.description;
      self.project.id = self.getCurrentProject.id;
    });
  }
};
</script>
